export default [
  {
    time: '12/2018 - 05/2019',
    position: 'Leader of 19Team',
    description: 'In Software Engineering subject at school'
  },
  {
    time: '12/2018 - Present',
    position: 'Member of Albert Team',
    description: ''
  },
  {
    time: '06/2018 - 06/2019',
    position: 'Deputy Head of Content',
    description: 'Public Speaking Club, University of Engineering and Technology'
  },
  {
    time: '06/2019 - Present',
    position: 'President',
    description: 'Public Speaking Club, University of Engineering and Technology'
  }
]
